import TimeUnitType from "../enums/timeUnitType"

export function ISO8601StringToDisplayString(durationString) {
  if (!durationString) {
    return '0 seconden'
  }

  const duration = parseISO8601Duration(durationString)
  const parts = []

  if (duration.Y > 0) {
    parts.push(`${duration.Y} ${duration.Y > 1 ? 'jaren' : 'jaar'}`)
  }
  if (duration.M > 0) {
    parts.push(`${duration.M} ${duration.M > 1 ? 'maanden' : 'maand'}`)
  }
  if (duration.D > 0) {
    parts.push(`${duration.D} ${duration.D > 1 ? 'dagen' : 'dag'}`)
  }
  if (duration.h > 0) {
    parts.push(`${duration.h} uur`)
  }
  if (duration.m > 0) {
    parts.push(`${duration.m} ${duration.m > 1 ? 'minuten' : 'minuut'}`)
  }
  if (duration.s > 0) {
    parts.push(`${duration.s} ${duration.s > 1 ? 'seconden' : 'seconde'}`)
  }

  if (parts.length === 2) {
    return parts.join(' en ')
  } else {
    return parts.join(', ')
  }
}

export function parseISO8601Duration(durationString) {
  if (!durationString) {
    return {
      Y: 0, M: 0, D: 0, h: 0, m: 0, s: 0,
    }
  }

  const matches = durationString.match(/P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?T?(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/)
  return {
    Y: matches[1] ? parseInt(matches[1]) : 0,
    M: matches[2] ? parseInt(matches[2]) : 0,
    D: matches[3] ? parseInt(matches[3]) : 0,
    h: matches[4] ? parseInt(matches[4]) : 0,
    m: matches[5] ? parseInt(matches[5]) : 0,
    s: matches[6] ? parseInt(matches[6]) : 0,
  }
}

export function durationToISO8601String(day, hour, minute, second) {
  const d = (day && day > 0) ? `${day}D` : ''
  const h = (hour && hour > 0) ? `${hour}H` : ''
  const m = (minute && minute > 0) ? `${minute}M` : ''
  const s = (second && second > 0) ? `${second}S` : ''

  // If no time is provided, return null
  if (`P${d}T${h}${m}${s}` === "PT") return null

  return `P${d}T${h}${m}${s}`
}

export function durationToISO8601StringSingleTimeUnit(value, timeUnitType) {
  switch (timeUnitType) {
    case TimeUnitType.DAYS:
      return `P${value}D`
    case TimeUnitType.HOURS:
      return `PT${value}H`
    case TimeUnitType.MINUTES:
      return `PT${value}M`
    case TimeUnitType.SECONDS:
      return `PT${value}S`
    default:
      throw new Error("Invalid time unit type")
  }
}

// TODO, there should be a Duration input component that can handle an iso duration, without splitting like this
export function iso8601StringToDurationSingleTimeUnit(isoString) {
  if (!isoString) {
    return { value: 0, timeUnitType: TimeUnitType.SECONDS }
  }

  const regex = /^P(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/
  const match = isoString.match(regex)

  if (!match) {
    throw new Error("Invalid ISO 8601 duration string")
  }

  if (match[1]) {
    return { value: parseInt(match[1], 10), timeUnitType: "DAYS" }
  }
  if (match[2]) {
    return { value: parseInt(match[2], 10), timeUnitType: "HOURS" }
  }
  if (match[3]) {
    return { value: parseInt(match[3], 10), timeUnitType: "MINUTES" }
  }
  if (match[4]) {
    return { value: parseInt(match[4], 10), timeUnitType: "SECONDS" }
  }

  throw new Error("Invalid ISO 8601 duration string with no recognizable units")
}

