<template>
    <v-row>
      <v-col v-if="hour" cols="6">
        <v-text-field class="overflow-label"
          persistent-placeholder
          :label="label"
          type="number"
          suffix="uur"
          v-model.number="localValue.h"
          min="0" max="23" :disabled="disabled" :required="required"
          :rules="[...getPositiveRules('Aantal moet positief zijn'), ...getRequiredRules('Aantal moet ingevuld worden')]"
        ></v-text-field>
      </v-col>
      <v-col v-if="minute" cols="6">
        <v-text-field
          persistent-placeholder
            type="number"
            suffix="minuten"
          v-model.number="localValue.m"
          min="0" max="59" :disabled="disabled" :required="required"
          :rules="[...getPositiveRules('Aantal moet positief zijn'), ...getRequiredRules('Aantal moet ingevuld worden')]"
        ></v-text-field>
      </v-col>
    </v-row>
</template>

<script>
import { getRequiredRules, getPositiveRules } from "@/shared/utils/inputRulesUtils.js";
import { parseISO8601Duration, durationToISO8601String } from "@/shared/utils/timeUtils.js";

export default {
  name: "DurationInput",
  props: {
    value: String,
    minute: {
      type: Boolean,
      default: true
    },
    hour: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Duurtijd"
    }
  },
  data() {
    return {
      localValue: parseISO8601Duration(this.value)
    }
  },
  watch: {
    // Watch for changes in the parent value and update localValue accordingly
    value: {
      immediate: true,
      handler(newVal) {
        this.localValue = parseISO8601Duration(newVal)
      }
    },
    // Watch localValue and emit changes to the parent
    localValue: {
      deep: true,
      handler(newValue) {
        const returnValue = durationToISO8601String(0, newValue.h, newValue.m, 0)
        this.$emit("input", returnValue)
        this.$emit("update:value", returnValue)
      }
    }
  },
  methods: {
    getRequiredRules,
    getPositiveRules
  }
}
</script>
