<template>
  <TaskDetailItemEditCard confirmation-message="Herhaling details werden succesvol gewijzigd" title="Bewerk herhaling details"
    update-store-action="tasksModule/updateTaskRecurrence"
    :update-store-action-payload="{ projectId: projectId, clientId: clientId, taskId: taskId, payload: getRecurrence }"
    :projectId="projectId" :clientId="clientId" :taskId="taskId" :promises.sync="promises">
    <template v-slot:form-fields>
      <!-- frequency -->
      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete v-model="recurrenceInformation.taskFrequency" :items="taskFrequencyValues" label="Frequentie"
            :rules="[...getRequiredRules('Frequentie moet ingevuld worden')]" required>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row v-if="enableRecurrenceFields">
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="3" md="2">
                <span class="d-flex h-100 align-center pl-0">Om de</span>
              </v-col>
              <v-col cols="3">
                <v-text-field required persistent-placeholder type="number"
                  v-model="recurrenceInformation.recurrenceDetails.recurrencePeriod" min=1
                  :rules="[...getBiggerThanZeroRules('Aantal moet groter zijn dan 0'), ...getRequiredRules('Aantal moet ingevuld worden')]">
                </v-text-field>
              </v-col>
              <v-col cols="6" md="7">
                <v-autocomplete v-model="recurrenceInformation.recurrenceDetails.recurrencePeriodType" :items="recurrencePeriodValues"
                  :rules="[...getRequiredRules('Frequentie moet ingevuld worden')]" required>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <!-- times per day -->
          <v-col cols="12" md="6">
            <v-text-field required persistent-placeholder type="number" label="Aantal mogelijke herhalingen per dag"
              v-model="recurrenceInformation.recurrenceDetails.timesPerDay" min=1
              :rules="[...getBiggerThanZeroRules('Aantal per dag moet groter zijn dan 0'), ...getRequiredRules('Aantal per dag moet ingevuld worden')]">
            </v-text-field>
          </v-col>
          
          <v-col cols="12" md="6">
            <DurationInput v-model="recurrenceInformation.recurrenceDetails.minGapBetweenRepetitions" label="Minimum tijd tussen herhalingen"/>
          </v-col>

          <v-col cols="12" md="6">
            <DurationInput v-model="recurrenceInformation.recurrenceDetails.maxGapBetweenRepetitions" label="Maximum tijd tussen herhalingen"/>
          </v-col>

          <!-- booleans -->
          <v-col cols="12" md="6">
            <v-checkbox v-model="recurrenceInformation.recurrenceDetails.sameDayForRecurrentTasks" label="Gebruik dezelfde dag voor herhaalde taken" />
          </v-col>
          <v-col cols="12" md="6">
            <v-checkbox v-model="recurrenceInformation.recurrenceDetails.samePersonForRecurrentTasks" label="Gebruik dezelfde persoon voor herhaalde taken" />
          </v-col>

        </v-row>
      </v-expand-transition>
      
    </template>
  </TaskDetailItemEditCard>
</template>

<script>
import TaskDetailItemEditCard from "@/views/planning/forms/TaskDetailItemEditCard.vue"
import routeNames from "@/router/RouteNames"
import { getRequiredRules, getBiggerThanZeroRules } from "@/shared/utils/inputRulesUtils.js"
import RecurrencePeriodType from "../../../shared/enums/RecurrencePeriodType"
import TaskFrequencyType from "../../../shared/enums/taskFrequencyType"
import { translateRecurrencePeriodType, translateTaskFrequency } from "../../../shared/utils/translateUtils"
import DurationInput from "../../../components/shared/fields/DurationInput.vue"

export default {
  name: "TaskDetailEditTaskRecurrence",
  props: {
    projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
    taskId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      routeNames,
      recurrenceInformation: {
          taskFrequency: TaskFrequencyType.ONE_TIME,
          recurrenceDetails: {
            recurrencePeriod: 1,
            recurrencePeriodType: RecurrencePeriodType.DAILY,
            timesPerDay: 1,
            minGapBetweenRepetitions: null,
            maxGapBetweenRepetitions: null,
            samePersonForRecurrentTasks: false,
            sameDayForRecurrentTasks: false,
          }
        },
      promises: [
        this.getTaskInformation()
      ],
      taskFrequencyValues: [
        {
          text: translateTaskFrequency(TaskFrequencyType.RECURRENT),
          value: TaskFrequencyType.RECURRENT
        },
        {
          text: translateTaskFrequency(TaskFrequencyType.ONE_TIME),
          value: TaskFrequencyType.ONE_TIME
        }
      ],
    }
  },
  components: {
    TaskDetailItemEditCard,
    DurationInput,
  },
  methods: {
    getRequiredRules,
    getBiggerThanZeroRules,
    getTaskInformation() {
      return this.$store.dispatch("tasksModule/fetchTaskRecurrence", { clientId: this.clientId, projectId: this.projectId, taskId: this.taskId })
        .then(result => { 
          this.recurrenceInformation = result
          // with recurrence, fill in default values if they are not present, to avoid null errors
          if (!this.recurrenceInformation.recurrenceDetails) {
            this.recurrenceInformation.recurrenceDetails = {
              recurrencePeriod: 1,
              recurrencePeriodType: RecurrencePeriodType.DAILY,
              timesPerDay: 1,
              minGapBetweenRepetitions: "PT0S",
              maxGapBetweenRepetitions: "PT0S",
              samePersonForRecurrentTasks: false,
              sameDayForRecurrentTasks: false,
            }
          }
        })
    }
  },
  computed: {
    getRecurrence() {
      if (this.recurrenceInformation.taskFrequency === TaskFrequencyType.ONE_TIME) {
        return {
          ...this.recurrenceInformation,
          recurrenceDetails: null
        }
      }

      return this.recurrenceInformation
    },
    recurrencePeriodValues() {
      if (!this.recurrenceInformation.recurrenceDetails) {
        return null
      }
      return [
        {
          text: translateRecurrencePeriodType(RecurrencePeriodType.DAILY, this.recurrenceInformation.recurrenceDetails.recurrencePeriod > 1),
          value: RecurrencePeriodType.DAILY
        },
        {
          text: translateRecurrencePeriodType(RecurrencePeriodType.WEEKLY, this.recurrenceInformation.recurrenceDetails.recurrencePeriod > 1),
          value: RecurrencePeriodType.WEEKLY
        },
        {
          text: translateRecurrencePeriodType(RecurrencePeriodType.MONTHLY, this.recurrenceInformation.recurrenceDetails.recurrencePeriod > 1),
          value: RecurrencePeriodType.MONTHLY
        },
        {
          text: translateRecurrencePeriodType(RecurrencePeriodType.YEARLY, this.recurrenceInformation.recurrenceDetails.recurrencePeriod > 1),
          value: RecurrencePeriodType.YEARLY
        },
      ]
    },
    enableRecurrenceFields() {
      return this.recurrenceInformation.taskFrequency === TaskFrequencyType.RECURRENT
    },
  }
}
</script>